/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2023/10/apple-unveils-new-macbook-pro-featuring-m3-chips/"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://festa.io/events/4247"
  }, "하시코프 사용자 모임 밋업"), " 홍보"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "애플 M3 제품군 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2023/10/apple-unveils-m3-m3-pro-and-m3-max-the-most-advanced-chips-for-a-personal-computer/"
  }, "애플의 보도자료(우리말)")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "요즘의 LLM"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "확 달라진 ", React.createElement(_components.a, {
    href: "https://bard.google.com/"
  }, "구글 바드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bard.google.com/updates?hl=ko"
  }, "Bard의 Google 렌즈 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://openai.com/gpt-4"
  }, "GPT4")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=11616&utm_source=slack&utm_medium=bot&utm_campaign=T04J76D4C"
  }, "ChatGPT (gpt-turbo-3.5): 20B")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.blog/changelog/2023-10-31-run-your-ml-workloads-on-github-actions-with-gpu-runners/"
  }, "깃헙액션 GPU hosted runners")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://support.google.com/youtube/answer/9730487?hl=en"
  }, "유튜브 업데이트")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://get.ing/"
  }, ".ing 도메인")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "k.ing 12.5만달러"), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
